import { todosRef, authRef, pricesRef, provider } from "../config/firebase";
import { FETCH_TODOS, FETCH_PRICES, FETCH_USER } from "./types";

export const addToDo = (newToDo, uid) => async dispatch => {
  todosRef
    .child(uid)
    .push()
    .set(newToDo);
};

export const completeToDo = (completeToDoId, uid) => async dispatch => {
  todosRef
    .child(uid)
    .child(completeToDoId)
    .remove();
};


export const fetchToDos = (uid) => async dispatch => {

  todosRef.child(uid).on("value", snapshot => {
    dispatch({
      type: FETCH_TODOS,
      payload: snapshot.val()
    });
  });

  // todosRef.on("value", snapshot => {
  //   dispatch({
  //     type: FETCH_TODOS,
  //     payload: snapshot.val()
  //   });
  // });
};

export const fetchPrices = () => dispatch => {

  pricesRef.on("value", snapshot => {
    dispatch({
      type: FETCH_PRICES,
      payload: snapshot.val()
    });
  });
};

export const fetchUser = () => dispatch => {
  authRef.onAuthStateChanged(user => {
    if (user) {
      dispatch({
        type: FETCH_USER,
        payload: user
      });
    } else {
      dispatch({
        type: FETCH_USER,
        payload: null
      });
    }
  });
};

export const signIn = () => dispatch => {
  authRef
    .signInWithPopup(provider)
    .then(result => {
      console.log(result)
    })
    .catch(error => {
      console.log(error);
    });
};

export const signOut = () => dispatch => {
  authRef
    .signOut()
    .then(() => {
      // Sign-out successful.
    })
    .catch(error => {
      console.log(error);
    });
};
