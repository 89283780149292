import React, { Component } from 'react';
import { Alert, Button, Table, Container, Row, Col, Input, Nav, Navbar, NavLink, NavbarBrand, NavItem } from 'reactstrap';

// import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import * as actions from "./actions";

import Mixpanel from 'mixpanel'

// import logo from './logo.svg';
import './App.css';
var mixpanel = Mixpanel.init('9391ed9ce45a422cc23038bc468ed476');

class App extends Component {

  constructor() {
    super()
    this.state = {
      list: null,
      auth: null,
      search_term: '',
      total_cost: 0
    }
  }

  componentWillMount = () => {

    
    this.props.fetchPrices();
    this.props.fetchUser();


  }

  componentWillUpdate(nextProps) {
    let { auth } = nextProps;
    console.log(auth)

    if (auth) {
      if (this.props.auth !== nextProps.auth) {
        this.props.fetchToDos(auth.uid);
      }
    }



  }



  handleSelectPrice = (data, price, marketName) => {
    console.log(data, price, marketName)

    const listItem = {
      name: data.ITEMS,
      brand: data.BRAND,
      units: data.UNIT,
      market: marketName.toLowerCase(),
      cost: price
    }
    this.props.addToDo(listItem, this.props.auth.uid)
  }

  handleChange(event) {
    mixpanel.track("Search for item");
    this.setState({ search_term: event.target.value });
  }


  render() {
    // console.log(this.props.auth)
    const { prices, data } = this.props
    const { search_term } = this.state

    let grocery_prices = null
    let shopping_list, price_keys = null
    let total = 0
    if (prices) {

      

      grocery_prices = prices.filter(function (item) {

        if (search_term.length < 1) {
          return item
        }

        return (
          item.ITEMS.toLowerCase().indexOf(search_term.toLowerCase()) >= 0 ||
          item.BRAND.toLowerCase().indexOf(search_term.toLowerCase()) >= 0
        )
      }).map((value, key) =>{

        // var price_keys = Object.keys(value).filter(function(item) {
        //   return item !== "ITEMS" && item !== "BRAND" && item !== "UNIT"
        // });
        // console.log(value)
        price_keys = Object.keys(value).filter(function(item) {
            return item !== "ITEMS" && item !== "BRAND" && item !== "UNIT"
          });
        // console.log(price_keys)

        return (
          <tr key={key}>
            <td className="text-left">{value.ITEMS}</td>
            <td className="text-left">{value.BRAND}</td>
            <td>{value.UNIT}</td>
            {/* <td onClick={(e) => this.handleSelectPrice(value, value['CARLTON BLACK ROCK'], 'CARLTON BLACK ROCK')}>{value['CARLTON BLACK ROCK']}</td>
            <td>{value['CHANNELL BLACK ROCK']}</td>
            <td>{value['CHANNELL COLLYMORE ROCK']}</td> */}

            {price_keys.map((data, key)=>
              <td onClick={(e) => this.handleSelectPrice(value, value[data], data)}>{value[data]}</td>
            )}

          </tr>
        )
      }

      
        
      );
    }

    if (data) {
      shopping_list = _.map(data, (value, key) => {

        total += value.cost;

        return (
          <li key={key} className="list-group-item text-left">
            <span className="text-right" style={{ float: "right" }}><span style={{fontSize:"12px"}}>{value.market}</span> <br /> ${value.cost}</span>
            
            <div>
              <h6 className="my-0">{value.name}</h6>
              <small className="text-muted">{value.brand}({value.units})</small>
            </div>

          </li>
        )
      })
    }


    return (
      <div className="App">

      
      
       
        <Navbar color="white" light expand="md" fixed="top" className="shadow-sm">
        <Container>
        <NavbarBrand href="/">PriceSnap</NavbarBrand>
          <Nav className="mr-auto" navbar>
          <NavItem>
            <Input type="text" style={{width:"100%"}} name="search_term" placeholder="Search for any item" onChange={(e)=>{this.handleChange(e)}} />
          </NavItem>
          </Nav>
        
          
          <Nav className="ml-auto" navbar>
          
        
            {
              this.props.auth ? (
                <NavItem>
                  Welcome back, {this.props.auth.displayName} &nbsp;&nbsp;
                  <Button
                    color="primary"
                    onClick={this.props.signOut}
                    id="sign-out-button"
                    alt={this.props.auth.uid}
                    className="btn-small"
                  >
                    Log out
                    </Button>
                </NavItem>) : (
                  <NavItem>
                    <Button
                      color="info"
                      onClick={this.props.signIn}
                      className="btn-small"
                    >
                      Sign In
                      </Button>
                  </NavItem>)
            }
          </Nav>
        </Container>
          
          
        </Navbar>
        
       
        <Container>
        {/* <section className="jumbotron text-center text-white" style={{background:"#1e365c"}}> */}
        <section className="jumbotron text-center" style={{background:"#fff", paddingTop:"100px"}}>
        <div className="container">
          <h1 className="jumbotron-heading">Search for Local Grocery Prices</h1>
          <p className="lead text-muted">
            PriceSnap is a free community where shoppers discover and share local prices in our grocery stores so that 
            we can keep track of price increases. 
            Want to contribute? <a target="_blank" href="https://barcode.typeform.com/to/MOkRD1">Contact Us</a>
          </p>
          <div class="form-group has-search">
              <span class="fa fa-search form-control-feedback"></span>
              <input type="text" class="form-control" placeholder="Search by typing the name of any item" style={{width:"100%"}} name="search_term" onChange={(e)=>{this.handleChange(e)}}/>
            </div>
        </div>
      </section>
          <Row>

            <Col sm={12}>

              {!prices?(
                <div className="sk-fading-circle">
                  <div className="sk-circle1 sk-circle"></div>
                  <div className="sk-circle2 sk-circle"></div>
                  <div className="sk-circle3 sk-circle"></div>
                  <div className="sk-circle4 sk-circle"></div>
                  <div className="sk-circle5 sk-circle"></div>
                  <div className="sk-circle6 sk-circle"></div>
                  <div className="sk-circle7 sk-circle"></div>
                  <div className="sk-circle8 sk-circle"></div>
                  <div className="sk-circle9 sk-circle"></div>
                  <div className="sk-circle10 sk-circle"></div>
                  <div className="sk-circle11 sk-circle"></div>
                  <div className="sk-circle12 sk-circle"></div>
                </div>
              ):
            <Table responsive>
            <thead>
              <tr>
                <th>Item</th>
                <th>Brand</th>
                <th>Units</th>
                {price_keys?(price_keys.map((value, key)=>
                  <th>{value}</th>
                )):<th></th>}
              </tr>
            </thead>
            <tbody>
              {grocery_prices.length > 0?
                grocery_prices:(
                <tr><td colspan="3"><Alert color="danger">Sorry, we could not find any items matching your search</Alert></td></tr>
                )}
            </tbody>
            </Table>

              }
              </Col>
            <Col sm={4}>

              <div style={{position:"fixed"}}>
              <h4>Your Cart</h4>
              {!this.props.auth?(
                <p>Please login in order to track prices</p>
              ):(
                
                <ul className="list-group mb-3">

                  {shopping_list}
                  
                  <li className="list-group-item text-left">
                    <span style={{ float: "right" }}>$ {total}</span>
                    <div>
                      <h6 className="my-0">Total</h6>
                      <small className="text-muted">USD</small>
                    </div>

                  </li>
                </ul>
              )}
                
              </div>
              
            </Col>
          </Row>
        </Container>

      </div >
    );
  }
}

const mapStateToProps = ({ data, auth, prices }) => {
  return {
    data,
    auth,
    prices
  };
};

export default connect(mapStateToProps, actions)(App);
