import { combineReducers } from "redux";

import data from "./dataReducer";
import prices from "./pricesReducer";
import auth from "./authReducer";

export default combineReducers({
  data,
  auth,
  prices
});
