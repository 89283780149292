import * as firebase from "firebase";

// import { FirebaseConfig } from "../config/keys";
firebase.initializeApp({
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID
});

const databaseRef = firebase.database().ref();
export const todosRef = databaseRef.child("todos");
export const pricesRef = databaseRef.child("prices");
export const authRef = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();
